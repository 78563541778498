<template functional>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9375 4.375H4.14062C3.53974 4.375 2.96347 4.6137 2.53859 5.03859C2.1137 5.46347 1.875 6.03974 1.875 6.64062V12.8125C1.875 13.3927 2.10547 13.9491 2.5157 14.3593C2.92594 14.7695 3.48234 15 4.0625 15H4.375V16.55C4.375 16.9677 4.54094 17.3683 4.83631 17.6637C5.13168 17.9591 5.53228 18.125 5.95 18.125H14.05C14.4677 18.125 14.8683 17.9591 15.1637 17.6637C15.4591 17.3683 15.625 16.9677 15.625 16.55V15H15.9375C16.5177 15 17.0741 14.7695 17.4843 14.3593C17.8945 13.9491 18.125 13.3927 18.125 12.8125V6.5625C18.125 5.98234 17.8945 5.42594 17.4843 5.0157C17.0741 4.60547 16.5177 4.375 15.9375 4.375ZM14.375 16.55C14.3747 16.6361 14.3404 16.7186 14.2795 16.7795C14.2186 16.8404 14.1361 16.8747 14.05 16.875H5.95C5.8639 16.8747 5.78141 16.8404 5.72053 16.7795C5.65965 16.7186 5.62531 16.6361 5.625 16.55V10.325C5.62531 10.2389 5.65965 10.1564 5.72053 10.0955C5.78141 10.0346 5.8639 10.0003 5.95 10H14.05C14.1361 10.0003 14.2186 10.0346 14.2795 10.0955C14.3404 10.1564 14.3747 10.2389 14.375 10.325V16.55ZM15.3906 8.12187C15.1994 8.13724 15.0081 8.09359 14.8425 7.99679C14.6769 7.9 14.5449 7.75473 14.4645 7.58059C14.384 7.40645 14.3589 7.21182 14.3925 7.02296C14.4262 6.83411 14.517 6.66012 14.6526 6.52448C14.7882 6.38883 14.9622 6.29806 15.1511 6.26442C15.3399 6.23078 15.5346 6.25588 15.7087 6.33633C15.8829 6.41679 16.0281 6.54873 16.1249 6.71435C16.2217 6.87996 16.2654 7.07129 16.25 7.2625C16.2322 7.48447 16.1359 7.69284 15.9784 7.8503C15.821 8.00776 15.6126 8.10403 15.3906 8.12187Z" fill="currentColor"/>
    <path d="M13.4373 1.875H6.5623C6.03653 1.87578 5.52856 2.06561 5.13113 2.40983C4.73369 2.75406 4.47329 3.22972 4.39746 3.75H15.6021C15.5263 3.22972 15.2659 2.75406 14.8685 2.40983C14.471 2.06561 13.9631 1.87578 13.4373 1.875Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPrint'
}
</script>

<style>
</style>
